import { classNameMap } from '../../../../constants/class.constants';
import { useStrapiCLPData } from '../../hooks/useStrapiCLPData';
import image from '../../../../assets/arrow-down-why.svg';
import { ArrowRight, Minus } from 'phosphor-react';
import { useMediaQuery } from 'usehooks-ts';

interface ApproachStatement {
  statementText: string;
  textColor: string;
}
const CLPWhyHQIllustration = () => {
  const { whyHQ } = useStrapiCLPData();
  const {
    title,
    ApproachStatements,
    ClientIndustries: { clientIndustriesHeading },
  } = whyHQ;

  const textContent = ApproachStatements.map((text: ApproachStatement, index: number) => (
    <span key={index} className={classNameMap[text.textColor]}>
      {text.statementText}
      {index < ApproachStatements.length - 1 && ' '}
    </span>
  ));

  const isDesktop = useMediaQuery('(min-width: 1024px)');

  return whyHQ ? (
    <div className="flex items-start gap-6">
      {isDesktop && (
        <div className="hidden lg:block">
          <img src={image} alt="Arrow pointing down" className="h-60" />
        </div>
      )}
      <div className="flex-1">
        <div className="flex items-start justify-between">
          <h2 className="text-3xl font-semibold leading-tight tracking-tight sm:text-4xl">
            {title}
          </h2>
          <div className="flex items-center">
            <Minus size={60} className="text-base-brand"></Minus>
            <Minus size={30} className="text-base-soft-black"></Minus>
            <Minus size={30} className="text-base-soft-black"></Minus>
            <ArrowRight size={30} className="text-base-soft-black"></ArrowRight>
          </div>
        </div>
        <div className="text-left">
          <p className="text-base lg:text-lg xl:text-xl">{textContent}</p>
          <p className="mt-4 text-sm font-light md:text-base">{clientIndustriesHeading}</p>
        </div>
      </div>
    </div>
  ) : null;
};

export default CLPWhyHQIllustration;
