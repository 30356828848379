import { useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';

const strapiDataSelector = (state: RootState) => state.strapi.publicStrapiData;
export const useStrapiCLPData = () => {
  const data: Record<string, any> = useAppSelector(strapiDataSelector);
  const strapiCLP = data.combinedLandingPage.data.attributes;
  const aboutHQ = strapiCLP.AboutHQ;
  const whyHQ = strapiCLP.WhyHQ;
  const ourServices = strapiCLP.OurServices;
  const landingPage = strapiCLP.LandingPage;
  const featureFlag = strapiCLP.FeatureFlag;
  const clpSEO = strapiCLP.Seo;
  const hqVision = strapiCLP.HQVision;
  const tagline = strapiCLP.Tagline;
  return {
    featureFlag,
    landingPage,
    ourServices,
    aboutHQ,
    whyHQ,
    clpSEO,
    hqVision,
    tagline,
  };
};
