import { useStrapiCLPData } from '../../hooks/useStrapiCLPData';
import { SwiperCarouselItem } from '../../../../components/SwiperCarouselItem';
import { SwiperCarousel } from '../../../../components/SwiperCarousel';
import {
  SliderCarouselModules,
  SWIPER_MODULES,
} from '../../../../constants/slider-carousel.constant';
import clsx from 'clsx';
import { SwiperCarouselITem, SwiperCarouselTypes } from '../../../../types';

const CLPHQClientsLogo = (props: { className?: string }) => {
  const {
    whyHQ: { ClientIndustries },
  } = useStrapiCLPData();

  const logos = ClientIndustries.clientIndustries.data.map(
    (item: {
      attributes: {
        alternativeText: string;
        url: string;
      };
    }) => {
      return {
        image: item.attributes.url,
        carouselType: SwiperCarouselTypes.LOGOS,
      };
    }
  );

  const slides = logos.map((logo: SwiperCarouselITem, index: number) => (
    <SwiperCarouselItem key={index} {...logo} />
  ));

  const modules = [SliderCarouselModules[SWIPER_MODULES.AUTOPLAY]];

  return (
    <div
      className={clsx(
        'flex items-center justify-center rounded-[20px] bg-base-soft-beige p-4',
        props.className
      )}
    >
      <SwiperCarousel
        slides={slides}
        modules={modules}
        autoPlay={true}
        slidesPerView={4}
      ></SwiperCarousel>
    </div>
  );
};

export default CLPHQClientsLogo;
