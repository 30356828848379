import { ArrowDown, ArrowRight, IconProps } from 'phosphor-react';
import { useStrapiHQServicesData } from '../../hooks';
import clsx from 'clsx';
import { useCallback } from 'react';
import { ServiceExplorerData } from '../../types';
import HQServicesActionItem from './HQServicesActionItem';
import pluralize from 'pluralize';

interface Props {
  className?: string;
}

const iconMap: Record<
  string,
  React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>
> = {
  Right: ArrowRight,
  Down: ArrowDown,
};

// Button theme map to handle color themes from Strapi
const classNameMap: Record<string, { buttonClassName: string; iconClassName: string }> = {
  Green: {
    buttonClassName: 'border-base-green bg-base-green text-white',
    iconClassName: 'text-white',
  },
  // eslint-disable-next-line camelcase
  Bright_Green: {
    buttonClassName: 'border-base-bright-green bg-base-bright-green text-white',
    iconClassName: 'text-white',
  },
  Black: {
    buttonClassName: 'border-black bg-black text-white',
    iconClassName: 'text-white',
  },
  // eslint-disable-next-line camelcase
  Soft_Black: {
    buttonClassName: 'border-base-soft-black bg-base-soft-black text-white',
    iconClassName: 'text-base-soft-black',
  },
  Brand: {
    buttonClassName: 'border-base-brand bg-base-brand text-white',
    iconClassName: 'text-white',
  },
  // eslint-disable-next-line camelcase
  Lime_Yellow: {
    buttonClassName: 'border-base-lime-yellow bg-base-lime-yellow text-white',
    iconClassName: 'text-white',
  },
  Tangerine: {
    buttonClassName: 'border-base-tangerine bg-base-tangerine text-white',
    iconClassName: 'text-white',
  },
};

const ServiceExplorer = ({ className }: Props) => {
  const { servicExplorer } = useStrapiHQServicesData();
  const { infoText, infoTextTheme, Buttons } = servicExplorer as ServiceExplorerData;

  const renderInfoText = useCallback(() => {
    const parts = infoText.split(/(\{\{.*?\}\})/);
    return parts.map((part, index) => {
      const match = part.match(/\{\{(.*?)\}\}/);
      if (match) {
        const buttonSlug = match[1];
        const button = Buttons.find((b) => b.slug === buttonSlug);
        if (button) {
          return (
            <span key={index} className="text-base font-light text-base-brand sm:text-lg">
              {pluralize.singular(button.label)}
            </span>
          );
        }
      }
      return part;
    });
  }, [infoText, Buttons]);

  const { buttonClassName } = classNameMap[infoTextTheme];

  const buttons = Buttons.map((button) => {
    const ButtonIconComponent = iconMap[button.icon];

    return (
      <HQServicesActionItem
        key={button.label}
        label={button.label}
        to={button.link}
        isExternal={button.isExternal}
        buttonStyle={button.style} // 'fill' or 'outline'
        IconComponent={ButtonIconComponent}
        buttonClassName={buttonClassName}
        className="text-base sm:text-lg"
      />
    );
  });

  return (
    <div className={clsx('pl-[var(--common-x-padding)] text-base sm:text-lg', className)}>
      <div className="flex flex-col items-center justify-between gap-4 rounded-[20px] bg-base-soft-beige p-6 font-light sm:flex-row">
        <h2 className="text-center text-base-soft-black"> {renderInfoText()}</h2>
        <div className="flex flex-col gap-4 xs:flex-row">{buttons}</div>
      </div>
    </div>
  );
};

export default ServiceExplorer;
