import clsx from 'clsx';
import { useStrapiCLPData } from '../../hooks/useStrapiCLPData';
import { classNameMap } from '../../../../constants/class.constants';
import { Link } from 'react-router-dom';

interface Props {
  className?: string;
}

interface CoreBeliefs {
  beliefText: string;
  textColor: string;
}

const CLPHQVision = (props: Props) => {
  const { className } = props;
  const {
    hqVision: { CoreBeliefs, supportingStatement, actionPrompt },
  } = useStrapiCLPData();

  return (
    <section className={clsx('relative flex flex-col gap-20', className)}>
      <div className="m-auto flex max-w-3xl flex-col items-center justify-center gap-8 text-left text-base leading-7 text-base-soft-black sm:text-center sm:text-lg md:text-xl lg:text-2xl">
        <p>
          {CoreBeliefs.map((text: CoreBeliefs, index: number) => (
            <span key={index} className={classNameMap[text.textColor]}>
              {text.beliefText}
              {index < CoreBeliefs.length - 1 && <br />}
            </span>
          ))}
        </p>
        <p className="inline">
          {supportingStatement}
          <Link to="/about" className="underline">
            {actionPrompt}
          </Link>
        </p>
      </div>
    </section>
  );
};

export default CLPHQVision;
