import { gql } from '@apollo/client';

export const CombinedLandingPageFragment = gql`
  fragment CombinedLandingPageFragment on CombinedLandingPageEntityResponse {
    data {
      attributes {
        OurServices {
          title
          description
          OurServiceTitle
          Buttons {
            slug
            isActive
            link
            isExternal
            label
          }
          Services {
            title
            description
            comingSoonButton
            slug
            theme
            Icon
            serviceImage {
              data {
                attributes {
                  url
                  alternativeText
                }
              }
            }
            button
            buttonSRLabel
            link
            isActive
            isExternalSite
          }
        }
        FeatureFlag {
          showHQPlus
          lastServiceCardTakesFullWidth
          showAboutUs
        }
        AboutHQ {
          WhatIsHQ {
            description
            title
          }
          GoldenRules {
            title
            description
            Rules {
              rule
            }
          }
          CoverImage {
            data {
              attributes {
                url
              }
            }
          }
        }
        WhyHQ {
          title
          answer
          GetInTouch {
            label
            isActive
            isExternal
            link
            slug
          }
          backgroundImage {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          ApproachStatements {
            statementText
            textColor
          }
          ClientIndustries {
            clientIndustriesHeading
            clientIndustries {
              data {
                attributes {
                  url
                  alternativeText
                }
              }
            }
          }
        }
        LandingPage {
          Slogan {
            mantra
          }
          Title {
            label
            color
          }
          CoverVideo {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          description
          Buttons {
            slug
            isActive
            link
            isExternal
            label
          }
        }
        HQVision {
          CoreBeliefs {
            beliefText
            textColor
          }
          supportingStatement
          actionPrompt
        }
        Tagline {
          primaryText
          secondaryText
          primaryTextColor
          secondaryTextColor
          image {
            data {
              attributes {
                url
              }
            }
          }
          imagePosition
        }
        Seo {
          pageTitle
          pageDescription
          ogTitle
          ogDescription
          ogSiteName
          canonicalUrl
          twitterTitle
          twitterDescription
          twitterUsername
          socialMediaImage {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          ogType
          twitterCardType
          preventIndexing
        }
      }
    }
  }
`;
