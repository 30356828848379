import { Link } from 'react-router-dom';

type props = {
  footer: any;
};

const FooterCard = ({ footer }: props) => {
  const footerHasMultipleLinks = footer.ListItems && footer.ListItems.length > 1;

  // wraps the footer links in a ul if there are multiple links and in a div if there is only one link
  const FooterLinksContainer = footerHasMultipleLinks ? 'ul' : 'div';
  // each link would be a li if there are multiple links and a div if there is only one link
  const FooterLinkContainer = footerHasMultipleLinks ? 'li' : 'div';

  return (
    <div className="mb-6 w-full font-light sm:w-1/2 lg:mb-0 lg:w-auto">
      <h2 className="mb-2 text-xs uppercase opacity-30 sm:mb-4 sm:text-sm">
        {footer.footerCategory}
      </h2>
      <FooterLinksContainer className="space-y-2 text-xs sm:text-sm">
        {footer.ListItems.map((listItems: any, index: any) => {
          return (
            <FooterLinkContainer key={index}>
              <Link to={listItems.footerListLink}>
                <span>{listItems.footerListItems}</span>
              </Link>
            </FooterLinkContainer>
          );
        })}
      </FooterLinksContainer>
    </div>
  );
};

export default FooterCard;
