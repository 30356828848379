import React, { useEffect, useState } from 'react';
import { Play, X } from 'phosphor-react';
import { Button } from '../Buttons';

export const VideoPlayer = ({
  src,
  altText,
  showIcons,
  onPlay,
}: {
  src: string | undefined;
  altText: string | undefined;
  showIcons: boolean;
  onPlay?: (isPlaying: boolean) => void; // Made optional
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  useEffect(() => {
    setIsButtonVisible(false);
    const timer = setTimeout(() => {
      setIsButtonVisible(true);
    }, 900);
    return () => clearTimeout(timer);
  }, [isExpanded]);

  const handlePlayClick = () => {
    setIsExpanded(true);
    if (onPlay) onPlay(true); // Only call onPlay if it exists
  };

  const handleCloseClick = () => {
    setIsExpanded(false);
    if (onPlay) onPlay(false); // Only call onPlay if it exists
  };

  if (!src) return <p>{altText}</p>;

  return (
    <div
      className="relative ml-auto overflow-hidden rounded-2xl"
      style={{
        width: isExpanded ? 'calc(100% - 2rem)' : '33.333%',
        maxHeight: '75vh',
        marginRight: isExpanded ? '20px' : '0',
        aspectRatio: '16 / 9',
        transition: 'all 0.8s cubic-bezier(0.25, 1, 0.5, 1)',
      }}
    >
      <video loop autoPlay playsInline muted className="h-full w-full object-cover">
        <source src={src} type="video/mp4" />
      </video>
      {showIcons && !isExpanded && isButtonVisible && (
        <Button
          className="absolute bottom-2 left-2 transition-opacity duration-300 sm:bottom-4 sm:left-4 md:bottom-6 md:left-6 lg:bottom-8 lg:left-8"
          onClick={handlePlayClick}
        >
          <Play
            className="h-8 w-8 sm:h-12 sm:w-12 md:h-16 md:w-16 lg:h-20 lg:w-20"
            weight="fill"
            color="white"
          />
        </Button>
      )}
      {showIcons && isExpanded && isButtonVisible && (
        <Button
          onClick={handleCloseClick}
          className="group absolute bottom-0 right-0 h-12 w-12 justify-center rounded-br-xl rounded-tl-xl rounded-bl-none rounded-tr-none bg-base-brand text-base font-medium text-white transition-opacity duration-300 sm:h-16 sm:w-16 md:h-20 md:w-20"
        >
          <X
            className="h-8 w-8 sm:h-10 sm:w-10 md:h-12 md:w-12 lg:h-14 lg:w-14"
            weight="bold"
            color="white"
          />
        </Button>
      )}
    </div>
  );
};
