import clsx from 'clsx';
import { useStrapiCLPData } from '../../../hooks/useStrapiCLPData';

interface Props {
  className?: string;
  isVideoPlaying?: boolean;
}

// Content in the Hero Section
const CLPHeroContent = ({ className, isVideoPlaying }: Props) => {
  const {
    landingPage: { Title: titleArray },
  } = useStrapiCLPData();

  const classNameMap: Record<string, string> = {
    // eslint-disable-next-line camelcase
    soft_black: 'text-base-soft-black',
    brand: 'text-base-brand',
    tangerine: 'text-base-tangerine',
    green: 'text-base-green',
    // eslint-disable-next-line camelcase
    bright_green: 'text-base-bright-green',
  };
  const titleParts = titleArray.map((title: { label: string; color: string }) => {
    const label = title.label;
    const color = title.color;
    const className = classNameMap[color];
    return { label, className };
  });

  return !isVideoPlaying ? (
    <section className={clsx('relative flex flex-col items-start gap-14', className)}>
      <div className="flex flex-col">
        <header className="relative flex h-[calc(var(--rectangle-height)/2)] flex-col items-start gap-2.5">
          <h1 className="text-balance text-zinc-950 relative max-w-[360px] break-words text-4xl font-semibold before:absolute before:-right-2 before:bottom-[calc(100%-1.25rem)] before:z-[-1] before:inline-block before:h-[max(calc(100vh/2),500px)] before:w-[clamp(500px,calc(100vw/2),var(--max-layout-width))] before:bg-base-soft-beige before:!content-['']">
            {titleParts.map((titlePart: { label: string; className: string }, idx: number) => {
              let addSpace = false;
              if (idx !== titleParts.length - 1) {
                addSpace = true;
              }

              return (
                <span
                  key={titlePart.label}
                  className={clsx('sm:whitespace-nowrap', titlePart.className)}
                >
                  {`${titlePart.label}${addSpace ? ' ' : ''}`}
                </span>
              );
            })}
          </h1>
        </header>
      </div>
      <div className="absolute -bottom-36 -left-6 z-30 flex">
        <hr className="hidden h-0.5 w-20 rotate-90 bg-base-brand xl:block" />
      </div>
    </section>
  ) : (
    <></>
  );
};

export default CLPHeroContent;
