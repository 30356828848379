import clsx from 'clsx';
import React from 'react';
import { useStrapiHQServicesData } from '../../hooks';

interface Props {
  className?: string;
}

const HQServicesHero = ({ className }: Props) => {
  const { hqServicesHeroSection } = useStrapiHQServicesData();
  const {
    title,
    subtitle,
    description,
    heroImage: {
      data: {
        attributes: { url: heroImage, alternativeText },
      },
    },
  } = hqServicesHeroSection;
  return (
    <section
      className={clsx(
        'flex w-full flex-col items-start justify-between gap-4 md:flex-row',
        className
      )}
    >
      <div className="flex flex-col gap-4 lg:w-1/2">
        <div className="flex flex-col">
          <div className="relative flex flex-col justify-center gap-4">
            <h1 className="text-balance text-zinc-950 relative max-w-[360px] break-words text-4xl font-semibold before:absolute before:-right-2 before:bottom-[calc(100%-1.25rem)] before:z-[-1] before:inline-block before:h-[max(calc(100vh/2),500px)] before:w-[clamp(500px,calc(100vw/2),var(--max-layout-width))] before:bg-base-soft-beige before:!content-['']">
              {title}
            </h1>
            <h2 className="max-w-sm text-lg font-medium text-base-soft-black md:text-xl">
              {subtitle}
            </h2>
            <p className="max-w-sm text-base font-medium text-base-soft-black md:text-lg">
              {description}
            </p>
          </div>
        </div>
      </div>
      <div className="relative h-[250px] w-full overflow-hidden md:h-[400px] md:w-1/2">
        <div className={clsx('absolute inset-0 overflow-hidden rounded-l-[20px]')}>
          <div className="relative h-full w-full md:-ml-8 md:w-[calc(100%+64px)]">
            <img
              src={heroImage}
              alt={alternativeText}
              className="h-full w-full object-cover object-center md:-ml-8 md:w-[calc(100%+64px)]"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HQServicesHero;
