import clsx from 'clsx';
import { ArrowDown, ArrowRight, IconProps } from 'phosphor-react';
import { HQServiceItemStrapi } from '../../types';
import HQServicesActionItem from './HQServicesActionItem';

const iconMap: Record<
  string,
  React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>
> = {
  Right: ArrowRight,
  Down: ArrowDown,
};

// Button theme map to handle color themes from Strapi
const classNameMap: Record<
  string,
  { buttonClassName: string; iconClassName: string; dividerClassName: string }
> = {
  Green: {
    buttonClassName: 'border-base-green bg-base-green text-white',
    iconClassName: 'text-white',
    dividerClassName: 'bg-base-green',
  },
  // eslint-disable-next-line camelcase
  Bright_Green: {
    buttonClassName: 'border-base-bright-green bg-base-bright-green text-white',
    iconClassName: 'text-white',
    dividerClassName: 'bg-base-bright-green',
  },
  Black: {
    buttonClassName: 'border-black bg-black text-white',
    iconClassName: 'text-white',
    dividerClassName: 'bg-black',
  },
  // eslint-disable-next-line camelcase
  Soft_Black: {
    buttonClassName: 'border-base-soft-black bg-base-soft-black text-white',
    iconClassName: 'text-base-soft-black',
    dividerClassName: 'bg-base-soft-black',
  },
  Brand: {
    buttonClassName: 'border-base-brand bg-base-brand text-white',
    iconClassName: 'text-white',
    dividerClassName: 'bg-base-brand',
  },
  // eslint-disable-next-line camelcase
  Lime_Yellow: {
    buttonClassName: 'border-base-lime-yellow bg-base-lime-yellow text-white',
    iconClassName: 'text-white',
    dividerClassName: 'bg-base-lime-yellow',
  },
  Tangerine: {
    buttonClassName: 'border-base-tangerine bg-base-tangerine text-white',
    iconClassName: 'text-white',
    dividerClassName: 'bg-base-tangerine',
  },
};

const HQServiceItem = ({ service, index }: { service: HQServiceItemStrapi; index: number }) => {
  const isEven = index % 2 === 0;
  const isBusinessService = service.slug === 'business';

  const { buttonClassName, dividerClassName } = classNameMap[service.serviceTheme];

  // Buttons
  const buttons = service.Buttons.map((button) => {
    const ButtonIconComponent = iconMap[button.icon];

    return (
      <HQServicesActionItem
        key={button.label}
        label={button.label}
        to={button.link}
        isExternal={button.isExternal}
        buttonStyle={button.style} // 'fill' or 'outline'
        IconComponent={ButtonIconComponent}
        buttonClassName={buttonClassName}
      />
    );
  });

  // Image and Service Details
  const src = service.serviceImage.data.attributes.url;
  const alt = service.serviceImage.data.attributes.alternativeText;

  const ImageSection = () => (
    <div className="relative h-[250px] w-full overflow-hidden md:h-[400px] md:w-1/2">
      <div
        className={clsx('absolute inset-0 overflow-hidden', {
          'md:rounded-r-[20px]': isEven,
          'md:rounded-l-[20px]': !isEven,
        })}
      >
        <div className="relative h-full w-full md:-ml-8 md:w-[calc(100%+64px)]">
          <img
            src={src}
            alt={alt}
            className="h-full w-full object-cover object-center md:-ml-8 md:w-[calc(100%+64px)]"
          />
        </div>
      </div>
    </div>
  );

  const ContentSection = () => (
    <div
      className={clsx(
        'flex w-full flex-col justify-center gap-6 p-4 text-base-soft-black md:w-1/2',
        isBusinessService ? 'bg-base-soft-beige' : ''
      )}
    >
      <h2 className="text-base font-medium sm:text-lg">{service.subtitle}</h2>
      <h3 className="text-xl font-semibold sm:text-4xl">{service.title}</h3>
      <p className="text-sm font-light sm:text-base">{service.description}</p>
      <div className="flex flex-col gap-4 sm:flex-row">{buttons}</div>
    </div>
  );

  const Divider = () => <div className={clsx('hidden w-[2px] md:block', dividerClassName)}></div>;

  return (
    <div
      className={clsx(
        'flex flex-col items-stretch gap-12',
        'md:flex-row md:gap-16',
        isEven ? 'md:pr-[var(--common-x-padding)]' : 'md:pl-[var(--common-x-padding)]',
        !isEven && 'md:flex-row-reverse' // Apply row-reverse for odd items on desktop
      )}
    >
      <ImageSection />
      {isEven ? <Divider /> : null}
      <ContentSection />
      {!isEven ? <Divider /> : null}
    </div>
  );
};

export default HQServiceItem;
