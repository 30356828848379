import { twMerge } from 'tailwind-merge';
import { NavItemMetadata } from '../../../../../types';
import Navbar from '../../../../../components/Navbar';

interface Props extends Omit<NavItemMetadata, 'slug'> {
  className?: string;
  srLabel?: string;
}

const CLPServicesGridActionItem = (props: Props) => {
  const { className, to, isExternal } = props;

  return (
    <Navbar.NavItem
      isExternal={isExternal}
      to={to}
      className={twMerge(
        'group flex items-center justify-center gap-1 text-base font-light text-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)]',
        className
      )}
    >
      <span
        aria-hidden="true"
        className="relative whitespace-nowrap text-2xl transition-transform group-hover:translate-x-1 motion-reduce:group-hover:translate-x-0 sm:text-3xl md:text-4xl"
      >
        {'->'}
      </span>
    </Navbar.NavItem>
  );
};

export default CLPServicesGridActionItem;
