import clsx from 'clsx';
import ReactMarkdown from 'react-markdown';

type Props = {
  children: string;
  className?: string;
};
const Markdown = ({ children, className }: Props) => {
  return (
    <ReactMarkdown
      components={{
        a: ({ children, ...rest }) => {
          return (
            <a
              {...rest}
              className="text-blue-600 underline visited:text-purple-600 hover:text-blue-800"
              target="_blank"
            >
              {children}
            </a>
          );
        },
      }}
      className={clsx('text-xs font-normal leading-normal text-base-soft-black', className)}
    >
      {children}
    </ReactMarkdown>
  );
};

export default Markdown;
