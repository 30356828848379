import clsx from 'clsx';
import React from 'react';
import { useStrapiHQFormData } from '../../hooks/useStrapiHQFormData';

type TitlePart = {
  label: string;
  className: string;
};

interface GetInTouchFormHeadingProps {
  className?: string;
}

const GetInTouchFormHeading = ({ className }: GetInTouchFormHeadingProps) => {
  const { GetInTouch } = useStrapiHQFormData();
  const { Title: titleArray, formDescription } = GetInTouch;

  const classNameMap: Record<string, string> = {
    // eslint-disable-next-line camelcase
    soft_black: 'text-base-soft-black',
    brand: 'text-base-brand',
    tangerine: 'text-base-tangerine',
    green: 'text-base-green',
    // eslint-disable-next-line camelcase
    bright_green: 'text-base-bright-green',
    // eslint-disable-next-line camelcase
    zinc: 'text-zinc-900',
  };
  const titleParts: TitlePart[] = titleArray.map((title: { label: string; color: string }) => {
    const label = title.label;
    const color = title.color;
    const className = classNameMap[color];
    return { label, className };
  });

  return (
    <header
      className={clsx(
        'flex w-full flex-col items-center justify-between xs:flex-row xs:items-center sm:items-center',
        className
      )}
    >
      <div className="flex w-full flex-col gap-4 text-center text-base-soft-black sm:mb-0">
        <h2 className="flex flex-wrap items-center justify-center gap-1 text-xl font-semibold tracking-[-0.02rem] text-base-soft-black md:text-4xl">
          {titleParts?.map((titlePart: { label: string; className: string }, idx: number) => {
            let addSpace = false;
            if (idx !== titleParts.length - 1) {
              addSpace = true;
            }

            return (
              <span
                key={titlePart.label}
                className={clsx('sm:whitespace-nowrap', titlePart.className)}
              >{`${titlePart.label}${addSpace ? ' ' : ''}`}</span>
            );
          })}
        </h2>
        <p className="text-base font-medium md:text-lg">{formDescription}</p>
      </div>
    </header>
  );
};

export default GetInTouchFormHeading;
