export enum SwiperCarouselTypes {
  COURSES,
  SPEAKERS,
  ARTICLES,
  LOGOS,
}

export type SwiperCarouselITem = {
  id?: number;
  divElement?: JSX.Element;
  label?: string;
  image?: string;
  showNavigation?: boolean;
  carouselType: SwiperCarouselTypes;
};
