import clsx from 'clsx';
import { IconProps } from 'phosphor-react'; // or your icon library
import Navbar from '../../../../components/Navbar';

interface HQServicesActionItemProps {
  label: string;
  to: string;
  isExternal?: boolean;
  buttonStyle: 'fill' | 'outline';
  IconComponent?: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>;
  className?: string;
  buttonClassName?: string;
  iconClassName?: string;
}

const HQServicesActionItem = ({
  label,
  to,
  isExternal = false,
  buttonStyle,
  IconComponent,
  className,
  buttonClassName,
  iconClassName,
}: HQServicesActionItemProps) => {
  const transitionClass =
    IconComponent?.displayName === 'ArrowDown'
      ? 'group-hover:translate-y-1' // Move down for ArrowDown
      : 'group-hover:translate-x-1'; // Move right for other icons (e.g., ArrowRight)

  return (
    <Navbar.NavItem
      isExternal={isExternal}
      to={to}
      className={clsx(
        'group flex flex-row items-center justify-between gap-x-6 rounded-lg border p-[12px_20px] text-base font-medium shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)]',
        buttonStyle === 'fill' ? buttonClassName : 'border-base-soft-black bg-transparent',
        className
      )}
    >
      <span className="flex items-center justify-between gap-4">
        <span className="font-medium">{label}</span>
        {IconComponent && (
          <IconComponent
            size={20}
            className={clsx('transition-transform', transitionClass, iconClassName)}
            aria-hidden="true"
          />
        )}
      </span>
    </Navbar.NavItem>
  );
};

export default HQServicesActionItem;
