import { gql } from '@apollo/client';
import {
  BrandLogoFragment,
  HqLiveFragment,
  CombinedLandingPageFragment,
  PrivacyPolicyFragment,
  TermsAndConditionsFragment,
  HQFormFragment,
  HQServicesFragment,
} from './fragments';

export const PublicSiteQuery = gql`
  ${BrandLogoFragment}
  ${PrivacyPolicyFragment}
  ${TermsAndConditionsFragment}
  ${HqLiveFragment}
  ${HQFormFragment}
  ${CombinedLandingPageFragment}
  ${HQServicesFragment}
  query {
    sideNavigator {
      ...BrandLogoFragment
    }
    hqlive {
      ...HqLiveFragment
    }
    hqForm {
      ...HQFormFragment
    }
    footer {
      data {
        attributes {
          FooterLists {
            footerCategory
            ListItems {
              footerListItems
              footerListLink
              footerListLinkSRLabel
            }
            slug
          }
          footerLogo {
            data {
              attributes {
                url
              }
            }
          }
          instagramLink
          linkedInLink
          twitterLink
          hqLogoSRLabel
          instagramLinkSRLabel
          linkedInLinkSRLabel
          twitterLinkSRLabel
          FooterInfo {
            copyrightText
            address
          }
          NewsLetterSection {
            title
            description
            Button {
              label
              isActive
              isExternal
              link
              slug
            }
          }
          HeaderSection {
            logo {
              data {
                attributes {
                  url
                }
              }
            }
            tagline
          }
        }
      }
    }
    combinedLandingPage {
      ...CombinedLandingPageFragment
    }
    landingpage {
      data {
        attributes {
          landingPage {
            ... on ComponentLandingPageCta {
              ctaTitle
              ctaDescription
              ctaBackground {
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
            }
            ... on ComponentHqplusPlanSelectorSection {
              title
              IndividualPlanSelectorLabel
              OrganisationPlanSelectorLabel
              IndividualPlanSelector {
                title
                description
              }
              OrganisationPlanSelector {
                title
                description
              }
            }
            ... on ComponentLandingPageFaq {
              title
              description
              questionAndAnswer {
                question
                answer
              }
            }
            ... on ComponentLandingPagePricingTable {
              title
              calculationComponent {
                title
                description
                organisationSizeSliderLabel
              }
              priceCard {
                teamPlanAvatar {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                individualPlanAvatar {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                enterprisePlanAvatar {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                perfectPlanLabel
                teamPlanTypeLabel
                individualPlanTypeLabel
                enterprisePlanTypeLabel
                description
                tenure
                getStartedButton
                contactUsButton
                discountDescription
                generalPriceDescription
                discountPercentage
                monthlyTenure
                yearlyTenure
              }
              pricingSummary
              pricingSummaryLabel
            }
            ... on ComponentLandingPageFeatures {
              individualDescription
              enterpriseDescription
              featureToggle {
                button
                slug
              }
              enterpriseFeatures {
                title
                description
                featureIcon {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
              individualFeatures {
                title
                description
                featureIcon {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
            ... on ComponentLandingPageLandingPage {
              title
              description
              requestADemo
              startYourFreeTrial
              highlightedTitlePart1
              highlightedTitlePart2
              landingPageImageLeft {
                carousel {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
              }
              landingPageImageRight {
                carousel {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
              }
              landingPageVideo {
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
              HQBanner
            }
            ... on ComponentLandingPageHandPickedFavorites {
              title
              description
              viewAllButton
              loadingFeaturedCoursesIconSRLabel
            }
            ... on ComponentLandingPageTestimonals {
              title
              description
              showTestimonial
              defaultTestimonial {
                testimony
                testimonyName
                testimonyCompany
                testimonialBg {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                testimonyImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
              testimonials {
                testimony
                testimonyName
                testimonyCompany
                testimonialBg {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                testimonyImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
            ... on ComponentLandingPageSpeakerSpotlight {
              title
              description
              speaker {
                speakerName
                speakerImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                speakerCourses
                totalHours
                speakerDesignation
              }
            }
            ... on ComponentLandingPageLandingPage {
              title
              description
              landingPageImage {
                carousel {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
            ... on ComponentSharedSeo {
              pageTitle
              pageDescription
              ogTitle
              ogDescription
              ogSiteName
              canonicalUrl
              twitterTitle
              twitterDescription
              twitterUsername
              socialMediaImage {
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
              ogType
              twitterCardType
              preventIndexing
            }
          }
          Courses {
            ... on ComponentLandingPageCoursesHome {
              header
              title1
              title2
              highlightedDescriptionPart1
              highlightedDescriptionPart2
              description
              coursesImage {
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
              knowMoreAboutUsButton
              loadingCoursesIconSRLabel
            }
            ... on ComponentSharedSeo {
              pageTitle
              pageDescription
              ogTitle
              ogDescription
              ogSiteName
              canonicalUrl
              twitterTitle
              twitterDescription
              twitterUsername
              socialMediaImage {
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
              ogType
              twitterCardType
              preventIndexing
            }
          }
          FAQ {
            ... on ComponentLandingPageGetInTouch {
              title
              description
              getInTouchButton
              getInTouchImage1 {
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
              getInTouchImage2 {
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
              getInTouchImage3 {
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
            }
            ... on ComponentSharedSeo {
              pageTitle
              pageDescription
              ogTitle
              ogDescription
              ogSiteName
              canonicalUrl
              twitterTitle
              twitterDescription
              twitterUsername
              socialMediaImage {
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
              ogType
              twitterCardType
              preventIndexing
            }
          }
          HqPlusLaunch {
            ... on ComponentHqplusSizzleVideo {
              sizzleVideo {
                data {
                  attributes {
                    url
                    size
                    alternativeText
                    previewUrl
                  }
                }
              }
              sizzleVideothumbnail {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
            ... on ComponentHqplusFeaturedFlag {
              showHqPlusLaunch
            }
            ... on ComponentHqplusLandingPage {
              subscribeNowButton
            }
          }
          AboutUs {
            ... on ComponentLandingPageAboutUsFeature {
              title
              description
              communityFeatureTitle
              reliablityFeatureTitle
              communityFeature {
                Listitem
              }
              reliablityFeature {
                Listitem
              }
            }
            ... on ComponentLandingPageAboutUsHome {
              title
              highlightedDescriptionPart1
              highlightedDescriptionPart2
              aboutUsCoverLeft {
                Description
                Image {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
              }
              aboutUsCoverRight {
                Description
                Image {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
              }
            }
            ... on ComponentLandingPageAboutUsStatistics {
              memberUnit
              memberCount
              followerUnit
              followerCount
              sessionUnit
              sessionCount
              aboutUsReputationLogo {
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
              aboutUsVideo {
                data {
                  attributes {
                    url
                    previewUrl
                  }
                }
              }
            }
            ... on ComponentSharedSeo {
              pageTitle
              pageDescription
              ogTitle
              ogDescription
              ogSiteName
              canonicalUrl
              twitterTitle
              twitterDescription
              twitterUsername
              socialMediaImage {
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
              ogType
              twitterCardType
              preventIndexing
            }
          }
        }
      }
    }
    hqService {
      ...HQServicesFragment
    }
    privacyPolicyRichText {
      ...PrivacyPolicyRichText
    }
    termsAndCondition {
      ...TermsAndCondition
    }
  }
`;
