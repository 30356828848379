import { Autoplay, Mousewheel, Navigation, Pagination } from 'swiper';
export enum SWIPER_MODULES {
  PAGINATION,
  NAVIGATION,
  MOUSEWHEEL,
  AUTOPLAY,
}

export const SliderCarouselModules = {
  [SWIPER_MODULES.PAGINATION]: Pagination,
  [SWIPER_MODULES.NAVIGATION]: Navigation,
  [SWIPER_MODULES.MOUSEWHEEL]: Mousewheel,
  [SWIPER_MODULES.AUTOPLAY]: Autoplay,
};

export const SliderBreakpoints = {
  450: {
    slidesPerView: 1,
    spaceBetween: 10,
  },
  768: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  1024: {
    slidesPerView: 3,
    spaceBetween: 30,
  },
};
