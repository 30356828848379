import Navbar from '../../../components/Navbar/Navbar';
import Footer from '../../publicSite/components/common/Footer/Footer';
import CLPHero from './CLPHero';
import SEOHelmetWrapper from '../../../components/SEOHelmet/SEOHelmet';
import { useStrapiCLPData } from '../hooks/useStrapiCLPData';
import CLPServices from './CLPServices';
import { GetInTouchFormMain } from '../../shared/components/GetInTouchForm';
import { CLPTagLine } from './CLPTagline';
import { CLPHQVision } from './CLPHQVision';
import { CLPWhyHQ } from './CLPWhyHQ';

const CombinedLandingPage = () => {
  const { clpSEO } = useStrapiCLPData();
  return (
    <div className="mx-auto flex flex-col items-center bg-page-bg-light [--max-layout-width:1792px] [--common-x-padding:26px] [--rectangle-height:16rem] xs:[--common-x-padding:52px] xl:[--common-x-padding:116px]">
      {/* SEOHelmetWrapper Component: Dynamically sets the document's head elements such as title, meta description, and other meta tags for Search Engine Optimization (SEO) purposes. */}
      {clpSEO && <SEOHelmetWrapper seo={clpSEO} />}
      <header className="w-full">
        <Navbar variant="light" />
      </header>
      <main className="flex w-full flex-col" id="main-content">
        <CLPHero className="z-10 pl-[var(--common-x-padding)]" />
        <CLPTagLine className="max-w-[var(--max-layout-width)] overflow-hidden px-[var(--common-x-padding)] py-16" />
        <CLPHQVision className="max-w-[var(--max-layout-width)] px-[var(--common-x-padding)] xl:py-16" />
        <div id="hq-services">
          <CLPServices className="w-full max-w-[var(--max-layout-width)] py-8 px-[var(--common-x-padding)]" />
        </div>
        <CLPWhyHQ className="max-w-[var(--max-layout-width)] py-8 sm:h-auto md:h-auto" />
        <GetInTouchFormMain className="max-w-[var(--max-layout-width)] py-8 px-0 xs:px-[var(--common-x-padding)]" />
      </main>
      <footer className="w-full">
        <Footer />
      </footer>
    </div>
  );
};

export default CombinedLandingPage;
