import { VideoPlayer } from '../../../../components/Video';
import { useStrapiCLPData } from '../../hooks/useStrapiCLPData';

const CLPHeroIllustration = (props: { onVideoPlay: (isPlaying: boolean) => void }) => {
  const { landingPage } = useStrapiCLPData();
  // Extract url and alternativeText, providing defaults to avoid errors
  const videoSrc = landingPage?.CoverVideo?.data?.attributes?.url || '';
  const alternativeText = landingPage?.CoverVideo?.data?.attributes?.alternativeText || '';

  return (
    <VideoPlayer
      src={videoSrc}
      altText={alternativeText}
      showIcons={true}
      onPlay={props.onVideoPlay}
    ></VideoPlayer>
  );
};

export default CLPHeroIllustration;
