import { useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';

const strapiDataSelector = (state: RootState) => state.strapi.publicStrapiData;
export const useStrapiDataHQLive = () => {
  const data: Record<string, any> = useAppSelector(strapiDataSelector);
  const strapiInPersonWorkshop = data.hqlive.data.attributes.IN_PERSON_WORKSHOP;
  let topNav;
  let workshop;
  let previousWorkshop;
  for (let i = 0; i <= strapiInPersonWorkshop?.length; i++) {
    if (strapiInPersonWorkshop[i]?.__typename === 'ComponentInpersonWorkshopTopNav') {
      topNav = (strapiInPersonWorkshop ?? [])[i];
    } else if (strapiInPersonWorkshop[i]?.__typename === 'ComponentInpersonWorkshopWorkshop') {
      workshop = (strapiInPersonWorkshop ?? [])[i];
    } else if (strapiInPersonWorkshop[i]?.__typename === 'ComponentLandingPagePreviousWorkshops') {
      previousWorkshop = (strapiInPersonWorkshop ?? [])[i];
    }
  }
  const strapiHero = data.hqlive.data.attributes.LANDING_PAGE;
  let heroPage;
  for (let i = 0; i <= strapiHero?.length; i++) {
    if (strapiHero[i]?.__typename === 'ComponentHqliveLandingPage') {
      heroPage = (strapiHero ?? [])[i];
    }
  }
  const strapiSpeaker = data.hqlive.data.attributes.SPEAKERS;
  let workshopSpeaker;
  for (let i = 0; i <= strapiSpeaker?.length; i++) {
    if (strapiSpeaker[i]?.__typename === 'ComponentLandingPageWorkshopSpeakers') {
      workshopSpeaker = (strapiSpeaker ?? [])[i];
    }
  }
  const strapiContactUs = data.hqlive.data.attributes.CONTACT_US;
  let contactUsForm;
  for (let i = 0; i <= strapiContactUs?.length; i++) {
    if (strapiContactUs[i]?.__typename === 'ComponentLandingPageContactUsForm') {
      contactUsForm = (strapiContactUs ?? [])[i];
    }
  }
  const strapiPrograms = data.hqlive.data.attributes.OUR_PROGRAMS;
  let ourProgram;
  for (let i = 0; i <= strapiPrograms?.length; i++) {
    if (strapiPrograms[i]?.__typename === 'ComponentLandingPageOurPrograms') {
      ourProgram = (strapiPrograms ?? [])[i];
    }
  }

  const { WorkshopList: workshopList } = workshop;

  const hqLivePageSEO = data.hqlive.data.attributes.Seo;

  return {
    topNav,
    workshopList,
    workshopSpeaker,
    previousWorkshop,
    heroPage,
    contactUsForm,
    ourProgram,
    hqLivePageSEO,
  };
};
