import clsx from 'clsx';
import CLPWhyHQIllustration from './CLPWhyHQIllustration';
import CLPHQClientsLogo from './CLPHQClientsLogo';

interface Props {
  className?: string;
}

// revisit

const CLPWhyHQ = (props: Props) => {
  const { className } = props;

  return (
    <section
      className={clsx(
        'relaive flex w-full flex-col gap-16 [--content-y-translate:-152px]',
        className
      )}
    >
      <div className="px-[var(--common-x-padding)]">
        <CLPWhyHQIllustration />
      </div>
      <div className="w-full pl-[var(--common-x-padding)]">
        <CLPHQClientsLogo />
      </div>
    </section>
  );
};

export default CLPWhyHQ;
