export const classNameMap: Record<string, string> = {
  // eslint-disable-next-line camelcase
  soft_black: 'text-base-soft-black',
  brand: 'text-base-brand',
  tangerine: 'text-base-tangerine',
  green: 'text-base-green',
  // eslint-disable-next-line camelcase
  bright_green: 'text-base-bright-green',
  // eslint-disable-next-line camelcase
  zinc: 'text-zinc-900',
};
