import { useStrapiCLPData } from '../../hooks/useStrapiCLPData';
import CLPServicesActionItem from './CLPServicesActionItem';

const CLPServicesHeading = () => {
  const {
    ourServices: { title, OurServiceTitle, Buttons: actionItem },
  } = useStrapiCLPData();
  return (
    <header className="flex flex-col items-start justify-between xs:flex-row xs:items-center sm:items-center">
      <div className="mb-4 flex flex-col gap-1 text-base-soft-black sm:mb-0">
        <span className="text-base font-medium">{OurServiceTitle}</span>
        <h2 className="text-3xl font-semibold leading-tight tracking-tight sm:text-4xl">{title}</h2>
      </div>
      <CLPServicesActionItem
        label={actionItem.label}
        isExternal={actionItem.isExternal}
        to={actionItem.link}
        {...actionItem}
      />
    </header>
  );
};

export default CLPServicesHeading;
