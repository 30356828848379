import { useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';

const strapiDataSelector = (state: RootState) => state.strapi.publicStrapiData;
export const useStrapiHQFormData = () => {
  const data: Record<string, any> = useAppSelector(strapiDataSelector);
  const hqFormData = data.hqForm.data.attributes;
  const GetInTouch = hqFormData.GetInTouch;
  const Newsletter = hqFormData.Newsletter;
  const ContactHeroSection = hqFormData.ContactHeroSection;
  return {
    GetInTouch,
    Newsletter,
    ContactHeroSection,
  };
};
