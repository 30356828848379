import { twMerge } from 'tailwind-merge';
import Navbar from '../../../../components/Navbar/Navbar';
import { NavItemMetadata } from '../../../../types';

interface Props extends Omit<NavItemMetadata, 'slug'> {
  className?: string;
  srLabel?: string;
}

const CLPServicesActionItem = (props: Props) => {
  const { className, to, isExternal, label } = props;

  return (
    <Navbar.NavItem
      isExternal={isExternal}
      to={to}
      className={twMerge(
        'group flex items-center justify-between gap-x-6 rounded-lg border border-base-soft-black p-[12px_20px] text-base font-medium text-base-soft-black shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)]',
        className
      )}
    >
      <span>{label}</span>
      <span
        aria-hidden="true"
        className="relative whitespace-nowrap transition-transform group-hover:translate-x-1 motion-reduce:group-hover:translate-x-0"
      >
        {'->'}
      </span>
    </Navbar.NavItem>
  );
};

export default CLPServicesActionItem;
