import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { classNameMap } from '../../../../constants/class.constants';
import { useStrapiCLPData } from '../../hooks/useStrapiCLPData';
import { useMediaQuery } from 'usehooks-ts';

interface Tagline {
  image: string;
  primaryText: string;
  secondaryText: string;
  primaryTextColor: string;
  secondaryTextColor: string;
  imagePosition: 'Center' | 'Bottom';
}
const CLPTagLine = (props: { className?: string }) => {
  const { tagline: Tagline } = useStrapiCLPData();

  const textSets = Tagline.map((tagLine: Tagline) => {
    return {
      secondaryTextColor: classNameMap[tagLine.secondaryTextColor],
      primaryTextColor: classNameMap[tagLine.primaryTextColor],
      primaryText: tagLine.primaryText,
      secondaryText: tagLine.secondaryText,
      image: tagLine.image,
      position: tagLine.imagePosition,
    };
  });
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % textSets.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const currentTextSet = textSets[currentIndex];
  const isLargeScreen = useMediaQuery('(min-width: 1024px)');

  return (
    <div
      className={clsx(
        'text-loop-container relative flex animate-textLoop items-baseline gap-1 xl:mt-28',
        props.className
      )}
    >
      <div
        className={clsx(
          'mt-8 text-base font-bold sm:text-xl md:text-2xl lg:text-3xl',
          `${currentTextSet.primaryTextColor}`
        )}
      >
        {currentTextSet.primaryText}
      </div>
      <div
        className={clsx(
          'subheading text-base sm:text-xl md:text-2xl lg:text-3xl',
          `${currentTextSet.secondaryTextColor}`
        )}
      >
        {currentTextSet.secondaryText}
      </div>
      {isLargeScreen && (
        <img
          src={currentTextSet.image.data.attributes.url}
          alt={`Tagline illustration for "${currentTextSet.primaryText}"`}
          className={clsx(
            'absolute left-[5%] h-auto max-h-[100px] w-auto max-w-[100px]',
            currentTextSet.position === 'Center' ? 'top-[30%]' : 'top-[50%]'
          )}
        />
      )}
    </div>
  );
};

export default CLPTagLine;
