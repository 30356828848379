import { useCallback, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './SwiperCarousel.css';
import { SwiperModule } from 'swiper/types';
import { SliderBreakpoints } from '../../constants/slider-carousel.constant';
import { ArrowRight, ArrowLeft } from 'phosphor-react';
import clsx from 'clsx';

interface SwiperCarouselProps {
  slides: JSX.Element[];
  spaceBetween?: number;
  slidesPerView?: number;
  navigation?: boolean;
  pagination?: boolean;
  mousewheel?: boolean;
  autoPlay?: boolean;
  modules: SwiperModule[];
}

const getDashLengths = (totalSlides: number) => {
  const maxDashLength = 50;
  const minDashLength = 10;

  const lengthStep = (maxDashLength - minDashLength) / (totalSlides - 1);
  const dashLengths = Array.from({ length: totalSlides }, (_, index) => {
    return `${maxDashLength - index * lengthStep}px`;
  });

  return dashLengths;
};

const SwiperCarousel = (props: SwiperCarouselProps) => {
  const dashLengths = getDashLengths(props.slides.length);
  const pagination = {
    clickable: props.pagination,
    renderBullet: (index: number, className: any) => {
      const dashLength = dashLengths[index];
      return `<span class="${className} custom-bullet" style="width: ${dashLength}"></span>`;
    },
  };

  const autoPlay = props.autoPlay ? { delay: 3000, disableOnInteraction: false } : false;
  const swiperRef = useRef<SwiperClass>();

  const handlePrev = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.slideNext();
  }, []);

  const updateNavigationState = () => {
    const swiper = swiperRef.current;
    if (!swiper) return;

    const prevButton = document.querySelector('.custom-swiper-button-prev');
    const nextButton = document.querySelector('.custom-swiper-button-next');

    if (prevButton instanceof HTMLElement) {
      prevButton.classList.toggle('swiper-button-disabled', swiper.isBeginning);
    }
    if (nextButton instanceof HTMLElement) {
      nextButton.classList.toggle('swiper-button-disabled', swiper.isEnd);
    }
  };

  const swiperParams = {
    spaceBetween: props.spaceBetween,
    slidesPerView: props.slidesPerView,
    modules: props.modules,
    navigation: {
      prevEl: '.custom-swiper-button-prev',
      nextEl: '.custom-swiper-button-next',
    },
    pagination: pagination,
    mousewheel: props.mousewheel,
    breakpoints: SliderBreakpoints,
    autoplay: autoPlay,
    loop: props.autoPlay,
    loopAdditionalSlides: 5,
    speed: 500,
    onSwiper: (swiper: SwiperClass | undefined) => {
      swiperRef.current = swiper;
    },
    onSlideChange: () => {
      updateNavigationState();
    },
  };

  return (
    <>
      <Swiper
        {...swiperParams}
        className={clsx('mySwiper', props.pagination && 'swiper-bottom-padding')}
      >
        {props.slides.map((slide, index) => (
          <SwiperSlide key={index}>{slide}</SwiperSlide>
        ))}
        {props.pagination && (
          <div className="absolute bottom-1 right-8 flex gap-3">
            <button
              className="custom-swiper-button-prev left-2 top-1/2 z-10 md:left-4 lg:left-6"
              onClick={handlePrev}
            >
              <ArrowLeft size={22} height="28" width="28" weight="bold" color="white" />
            </button>
            <button
              className="custom-swiper-button-next right-2 top-1/2 z-10 md:right-4 lg:right-6"
              onClick={handleNext}
            >
              <ArrowRight size={22} height="28" width="28" weight="bold" color="white" />
            </button>
          </div>
        )}
      </Swiper>
    </>
  );
};

export default SwiperCarousel;
