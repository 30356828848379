import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import TextInput from '../../../../components/FormElements/TextInput';
import { fieldHasError } from '../../../../utils/react-hook-form';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import ErrorMessage from '../../../../components/FormElements/ErrorMessage';
import { ArrowRight } from 'phosphor-react';
import React, { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Toast } from '../../../../components/Toast';
import {
  CheckboxInput,
  DropdownIndicator,
  FloatingLabel,
  Label,
  SelectController,
  TextAreaInput,
} from '../../../../components/FormElements';
import { BasicSpinner } from '../../../../components/Spinners';
import {
  FloatingFormFieldWrapper,
  FormActionButton,
  FormFieldWrapper,
} from '../../../../components/FormControls';
import { useStrapiHQFormData } from '../../hooks/useStrapiHQFormData';
import { TYPE_OF_ENGAGEMENT } from '../../constants/form';
import { submitHubspotForm } from '../../api';
import { HubSpotForm } from '../../types';
import FORM_TYPES from '../../constants/hubspot';
import { useStrapiDataHQLive } from '../../hooks/useStrapiDataHQLive';
import Markdown from '../../../../components/Markdown/Markdown';
import { FormStyleProvider } from '../../context';

type Schema = {
  firstNameLabel: string;
  firstNameRequired: string;
  firstNameMinValue: number;
  firstNameMinError: string;
  firstNameMaxError: string;
  firstNameMaxValue: number;
  validFirstName: string;
  namePattern: string;
  lastNameLabel: string;
  lastNameRequired: string;
  lastNameMinValue: number;
  lastNameMinError: string;
  lastNameMaxValue: number;
  lastNameMaxError: string;
  vaildLastName: string;
  emailLabel: string;
  emailRequired: string;
  validEmail: string;
  organizationLabel: string;
  organizationRequired: string;
  organizationSizeLabel: string;
  organizationSizeRequired: string;
  organizationMinValue: number;
  organizationMinError: string;
  organizationMaxError: string;
  organizationMaxValue: number;
  typeOfEngagementLabel: string;
  typeOfEngagementRequired: string;
  typeOfConsultingLabel: string;
  jobTitleLabel: string;
  locationLabel: string;
  industryLabel: string;
  howDidYouHearAboutUsLabel: string;
  messageMaxvalue: number;
  messageMaxError: string;
  preferredSpeakersMaxError: string;
  preferredSpeakersMaxValue: number;
  consentRequired: string;
};

const getSchema = (data: Schema) => {
  const {
    firstNameLabel,
    firstNameRequired,
    firstNameMinValue,
    firstNameMinError,
    firstNameMaxError,
    firstNameMaxValue,
    validFirstName,
    namePattern,
    lastNameLabel,
    lastNameRequired,
    lastNameMinValue,
    lastNameMinError,
    lastNameMaxValue,
    lastNameMaxError,
    vaildLastName,
    emailLabel,
    emailRequired,
    validEmail,
    organizationLabel,
    organizationRequired,
    organizationSizeLabel,
    organizationSizeRequired,
    organizationMinValue,
    organizationMinError,
    organizationMaxError,
    organizationMaxValue,
    typeOfEngagementLabel,
    typeOfEngagementRequired,
    typeOfConsultingLabel,
    jobTitleLabel,
    locationLabel,
    industryLabel,
    howDidYouHearAboutUsLabel,
    messageMaxvalue,
    messageMaxError,
    preferredSpeakersMaxError,
    preferredSpeakersMaxValue,
    consentRequired,
  } = data;
  const GetInTouchSchema = yup.object({
    firstName: yup
      .string()
      .trim()
      .min(firstNameMinValue, firstNameMinError)
      .max(firstNameMaxValue, firstNameMaxError)
      .required(firstNameRequired)
      .matches(new RegExp(namePattern), validFirstName)
      .label(firstNameLabel),
    lastName: yup
      .string()
      .trim()
      .min(lastNameMinValue, lastNameMinError)
      .max(lastNameMaxValue, lastNameMaxError)
      .required(lastNameRequired)
      .matches(new RegExp(namePattern), vaildLastName)
      .label(lastNameLabel),
    email: yup.string().trim().email(validEmail).required(emailRequired).label(emailLabel),
    organization: yup
      .string()
      .trim()
      .min(organizationMinValue, organizationMinError)
      .max(organizationMaxValue, organizationMaxError)
      .required(organizationRequired)
      .label(organizationLabel),
    organizationSize: yup
      .string()
      .required(organizationSizeRequired)
      .default('')
      .label(organizationSizeLabel),
    typeOfEngagement: yup
      .string()
      .required(typeOfEngagementRequired)
      .default('')
      .label(typeOfEngagementLabel),
    typeOfConsulting: yup.string().default('').label(typeOfConsultingLabel),
    preferredSpeakers: yup
      .array(
        yup.object({
          label: yup.string(),
          value: yup.string(),
        })
      )
      .max(preferredSpeakersMaxValue, preferredSpeakersMaxError),
    jobTitle: yup.string().trim().label(jobTitleLabel),
    location: yup.string().trim().label(locationLabel),
    industry: yup.string().default('').label(industryLabel),
    howDidYouHearAboutUs: yup.string().default('').label(howDidYouHearAboutUsLabel),
    message: yup.string().trim().max(messageMaxvalue, messageMaxError),
    consent: yup.bool().oneOf([true], consentRequired).required(consentRequired),
  });
  return GetInTouchSchema;
};

const GetInTouchForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { GetInTouch } = useStrapiHQFormData();
  const { ourProgram } = useStrapiDataHQLive();
  const GetInTouchSchema = getSchema(GetInTouch);
  type GetInTouchFormData = yup.InferType<typeof GetInTouchSchema>;
  const {
    firstNameLabel,
    firstNamePlaceholder,
    lastNameLabel,
    lastNamePlaceholder,
    emailLabel,
    emailPlaceholder,
    organizationLabel,
    organizationPlaceholder,
    organizationSizeLabel,
    organizationSizePlaceholder,
    typeOfEngagementLabel,
    typeOfEngagementPlaceholder,
    preferredSpeakersLabel,
    preferredSpeakersPlaceholder,
    TypeOfEngagementOptions,
    typeOfConsultingLabel,
    typeOfConsultingPlaceholder,
    jobTitleLabel,
    jobTitlePlaceholder,
    locationLabel,
    locationPlaceholder,
    industryLabel,
    industryPlaceholder,
    howDidYouHearAboutUsLabel,
    howDidYouHearAboutUsPlaceholder,
    messageLabel,
    messagePlaceholder,
    contactUsButton,
    thankYouMessage,
    errorMessage,
    HubspotTicketPriority: { mediumPriorityRangeValue, highPriorityRangeValue },
    PreferredSpeakers,
    IndustryOptions,
    HowDidYouHearAboutUsOptions,
    OrganizationSizeOptions,
    TypeOfConsulting,
    Consent,
  } = GetInTouch;

  // Type of Engagement Options
  const typeOfEngagementDropdown = useMemo(() => {
    if (TypeOfEngagementOptions && TypeOfEngagementOptions.length > 0) {
      // map response to the options format
      const options = TypeOfEngagementOptions.map(
        (engagement: { label: string; value: string }) => {
          return {
            label: engagement.label,
            value: engagement.value,
          };
        }
      );

      return options;
    }

    return [];
  }, [TypeOfEngagementOptions, typeOfEngagementPlaceholder]);

  // Preferred Speakers Options
  const speakerOptions: Array<{ label: string; value: string }> = useMemo(() => {
    return PreferredSpeakers.map((speaker: { speakerName: string }) => {
      return { label: speaker.speakerName, value: speaker.speakerName };
    });
  }, [PreferredSpeakers]);

  // Type of Consulting Options
  const { programs } = ourProgram;
  const consultingDropdown: Array<{ label: string; value: string; slug: string }> = useMemo(() => {
    const programOptions = programs.map((program: any) => {
      return { label: program.programTitle, value: program.programTitle, slug: program.slug };
    });

    return programOptions;
  }, [typeOfConsultingPlaceholder, TypeOfConsulting]);

  // Industry Options
  const industryDropdown: Array<{ label: string; value: string }> = useMemo(() => {
    const industryOptions = IndustryOptions.map((industry: { label: string; value: string }) => {
      return { label: industry.label, value: industry.value };
    });

    return industryOptions;
  }, [IndustryOptions, industryPlaceholder]);

  // How did you hear about us Options
  const howDidYouHearAboutUsDropdown: Array<{ label: string; value: string }> = useMemo(() => {
    const howDidYouHearAboutUsOptions = HowDidYouHearAboutUsOptions.map(
      (option: { label: string; value: string }) => {
        return { label: option.label, value: option.value };
      }
    );

    return howDidYouHearAboutUsOptions;
  }, [howDidYouHearAboutUsPlaceholder, HowDidYouHearAboutUsOptions]);

  // Organization Size Options
  const organizationSizeDropdown: Array<{ label: string; value: string; slug: string }> =
    useMemo(() => {
      const organizationSizeOptions = OrganizationSizeOptions.map(
        (option: { label: string; value: string }) => {
          return { label: option.label, value: option.value };
        }
      );

      return organizationSizeOptions;
    }, [organizationSizePlaceholder, OrganizationSizeOptions]);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<GetInTouchFormData>({
    resolver: yupResolver(GetInTouchSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      organization: '',
      organizationSize: '',
      typeOfEngagement: '',
      typeOfConsulting: '',
      preferredSpeakers: [],
      jobTitle: '',
      location: '',
      industry: '',
      howDidYouHearAboutUs: '',
      message: '',
      consent: false,
    },
    mode: 'onChange',
  });
  const engagementTypeCheck: TYPE_OF_ENGAGEMENT = watch('typeOfEngagement') as TYPE_OF_ENGAGEMENT;

  // type of engagements
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedEngagementType = searchParams.get('engagement_type');
    if (selectedEngagementType) {
      const selectedTypeOfEngagement = selectedEngagementType
        ? typeOfEngagementDropdown.filter(
            (type: { label: string; value: string }) => type.value === selectedEngagementType
          )
        : typeOfEngagementDropdown;

      if (selectedTypeOfEngagement && selectedTypeOfEngagement.length > 0) {
        setValue('typeOfEngagement', selectedTypeOfEngagement[0].value);
      }
    }
  }, [typeOfEngagementDropdown, location.search, setValue]);

  // type of consulting program
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedProgram = searchParams.get('program_type');
    if (selectedProgram) {
      const selectedConsultingProgram = selectedProgram
        ? consultingDropdown.filter(
            (type: { label: string; value: string; slug: string }) => type.slug === selectedProgram
          )
        : consultingDropdown;
      if (selectedConsultingProgram && selectedConsultingProgram.length > 0) {
        setValue('typeOfConsulting', selectedConsultingProgram[0].value);
      }
    }
  }, [consultingDropdown, location.search, setValue]);

  // preferred speakers
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedSpeaker = searchParams.get('speaker');

    if (selectedSpeaker) {
      const speakerOption = speakerOptions.find((opt) => opt.value === selectedSpeaker);

      if (speakerOption) {
        setValue('preferredSpeakers', [speakerOption]);
      }
    }
  }, [location.search, setValue, speakerOptions]);

  //  useEffect to clear the selected values when options changes
  useEffect(() => {
    if (!(engagementTypeCheck == TYPE_OF_ENGAGEMENT.SPEAKER_ENGAGEMENT)) {
      setValue('preferredSpeakers', undefined);
    }
    if (!(engagementTypeCheck == TYPE_OF_ENGAGEMENT.CONSULTING_PROGRAM)) {
      setValue('typeOfConsulting', '');
    }
  }, [engagementTypeCheck]);

  const formSubmitHandler: SubmitHandler<GetInTouchFormData> = async (data) => {
    const {
      firstName,
      email,
      lastName,
      organization,
      organizationSize,
      jobTitle,
      location,
      industry,
      preferredSpeakers,
      typeOfEngagement,
      typeOfConsulting,
      howDidYouHearAboutUs,
      message,
      consent,
    } = data;

    const TYPE_OF_ENGAGEMENT_MAP: Record<string, string> = {
      // eslint-disable-next-line camelcase
      speaker_engagement: 'Speaker Engagement',
      // eslint-disable-next-line camelcase
      in_person_workshop: 'In Person Workshop',
      // eslint-disable-next-line camelcase
      consulting_program: 'Consulting Program',
      // eslint-disable-next-line camelcase
      hq_plus: 'HQ+',
    };

    const finalTypeOfEnagagement = TYPE_OF_ENGAGEMENT_MAP[typeOfEngagement];

    const nameOfPreferredSpeaker = preferredSpeakers?.map((speaker) => {
      return speaker?.label as string;
    });

    const speakerContext = `with the preferred speakers of ${nameOfPreferredSpeaker}`;
    const consultingProgramContext = `of ${typeOfConsulting}`;

    const ticketContent = `Hi I am  ${firstName} ${lastName} from ${organization}  we are a organization of around ${organizationSize} and we are interested in having a engagement of type ${finalTypeOfEnagagement}  ${
      typeOfConsulting.length ? consultingProgramContext : ''
    } ${preferredSpeakers?.length ? speakerContext : ''}`;
    const ticketSubject = `Hi I am ${firstName} ${lastName} from ${organization}`;

    const ticketPriority =
      Number(organizationSize) > highPriorityRangeValue
        ? 'High'
        : organizationSize < highPriorityRangeValue && organizationSize > mediumPriorityRangeValue
        ? 'Medium'
        : 'Low';

    const payload: HubSpotForm = {
      fields: [
        {
          objectTypeId: '0-1',
          name: 'email',
          value: email,
        },
        {
          objectTypeId: '0-1',
          name: 'firstname',
          value: firstName,
        },
        {
          objectTypeId: '0-1',
          name: 'lastname',
          value: lastName,
        },
        {
          objectTypeId: '0-1',
          name: 'jobtitle',
          value: jobTitle || '',
        },
        {
          objectTypeId: '0-1',
          name: 'message',
          value: message || '',
        },
        {
          objectTypeId: '0-1',
          name: 'city',
          value: location || '',
        },
        {
          objectTypeId: '0-1',
          name: 'company',
          value: organization,
        },
        {
          objectTypeId: '0-1',
          name: 'company_size',
          value: organizationSize,
        },
        {
          objectTypeId: '0-1',
          name: 'how_did_you_hear_about_us_please_use',
          value: howDidYouHearAboutUs,
        },
        {
          objectTypeId: '0-2',
          name: 'type_of_engagement',
          value: finalTypeOfEnagagement,
        },
        {
          objectTypeId: '0-2',
          name: 'speaker',
          value: (nameOfPreferredSpeaker || '').toString(),
        },
        {
          objectTypeId: '0-2',
          name: 'consulting_programs',
          value: typeOfConsulting,
        },
        {
          objectTypeId: '0-2',
          name: 'companyindustry',
          value: industry,
        },
        {
          objectTypeId: '0-1',
          name: 'TICKET.content',
          value: ticketContent,
        },
        {
          objectTypeId: '0-1',
          name: 'TICKET.subject',
          value: ticketSubject,
        },
        {
          objectTypeId: '0-1',
          name: 'TICKET.hs_ticket_priority',
          value: ticketPriority,
        },
        {
          objectTypeId: '0-1',
          name: 'TICKET.source_type',
          value: process.env.REACT_APP_USHG_PUBLIC_SITE_DOMAIN || '',
        },
      ],
      legalConsentOptions: {
        consent: {
          consentToProcess: consent,
          text: Consent.processConsent,
          communications: [
            {
              value: consent,
              subscriptionTypeId: Consent.subscriptionTypeId,
              text: Consent.communicationConsent,
            },
          ],
        },
      },
    };
    try {
      setIsLoading(true);
      await submitHubspotForm(payload, FORM_TYPES.GET_IN_TOUCH);

      toast.custom((t) => <Toast variant="success" Title={thankYouMessage} toastInstance={t} />);
    } catch (error: any) {
      console.error('Validation error ', error);
      toast.custom((t) => <Toast variant="error" Title={errorMessage} toastInstance={t} />);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormStyleProvider backgroundColor="bg-base-soft-beige">
      <form
        onSubmit={handleSubmit(formSubmitHandler)}
        className="grid w-full grid-cols-1 gap-4 rounded-none bg-base-soft-beige p-4 xs:rounded-[20px] sm:p-16"
      >
        {/* Type of Engagement Input */}
        <FloatingFormFieldWrapper>
          <SelectController
            name="typeOfEngagement"
            id="type_of_engagement_input"
            control={control}
            options={typeOfEngagementDropdown}
            placeholder={typeOfEngagementPlaceholder}
            hasError={fieldHasError(errors, 'typeOfEngagement')}
            isMulti={false}
            customComponents={{ DropdownIndicator }}
          />
          <FloatingLabel
            htmlFor="type_of_engagement_input"
            label={typeOfEngagementLabel}
            className="required"
          ></FloatingLabel>
          <HookFormErrorMessage
            name="typeOfEngagement"
            errors={errors}
            render={({ message }) => <ErrorMessage message={message} />}
          />
        </FloatingFormFieldWrapper>

        {/* Type of consulting program type */}
        {engagementTypeCheck === TYPE_OF_ENGAGEMENT.CONSULTING_PROGRAM && (
          <FloatingFormFieldWrapper>
            <SelectController
              name="typeOfConsulting"
              id="type_of_consulting_input"
              control={control}
              options={consultingDropdown}
              placeholder={typeOfConsultingPlaceholder}
              hasError={fieldHasError(errors, 'typeOfConsulting')}
              isMulti={false}
              customComponents={{ DropdownIndicator }}
            />
            <FloatingLabel
              htmlFor="type_of_consulting_input"
              label={typeOfConsultingLabel}
            ></FloatingLabel>
          </FloatingFormFieldWrapper>
        )}

        {/* Preferred Speakers (Optional) Input */}
        {engagementTypeCheck === TYPE_OF_ENGAGEMENT.SPEAKER_ENGAGEMENT && (
          <FloatingFormFieldWrapper>
            <SelectController
              name="preferredSpeakers"
              id="preferred_speakers_input"
              control={control}
              options={speakerOptions}
              placeholder={preferredSpeakersPlaceholder}
              hasError={fieldHasError(errors, 'preferredSpeakers')}
              isMulti={true}
              customComponents={{ DropdownIndicator }}
            />
            <FloatingLabel
              htmlFor="preferred_speakers_input"
              label={preferredSpeakersLabel}
            ></FloatingLabel>
            <HookFormErrorMessage
              name="preferredSpeakers"
              errors={errors}
              render={({ message }) => <ErrorMessage message={message} />}
            />
          </FloatingFormFieldWrapper>
        )}

        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          {/* First Name Input */}
          <FloatingFormFieldWrapper>
            <TextInput
              id="first_name_input"
              placeholder={firstNamePlaceholder}
              type="text"
              hasError={fieldHasError(errors, 'firstName')}
              {...register('firstName')}
            />
            <FloatingLabel htmlFor="first_name_input" className="required" label={firstNameLabel} />
            <HookFormErrorMessage
              name="firstName"
              errors={errors}
              render={({ message }) => <ErrorMessage message={message} />}
            />
          </FloatingFormFieldWrapper>

          {/* Last Name Input */}
          <FloatingFormFieldWrapper>
            <TextInput
              id="last_name_input"
              placeholder={lastNamePlaceholder}
              type="text"
              hasError={fieldHasError(errors, 'lastName')}
              {...register('lastName')}
            />
            <FloatingLabel className="required" htmlFor="last_name_input" label={lastNameLabel} />
            <HookFormErrorMessage
              name="lastName"
              errors={errors}
              render={({ message }) => <ErrorMessage message={message} />}
            />
          </FloatingFormFieldWrapper>
        </div>

        {/* Email Address Input */}
        <FloatingFormFieldWrapper>
          <TextInput
            id="email_input"
            placeholder={emailPlaceholder}
            type="text"
            hasError={fieldHasError(errors, 'email')}
            {...register('email')}
          />
          <FloatingLabel className="required" htmlFor="email_input" label={emailLabel} />
          <HookFormErrorMessage
            name="email"
            errors={errors}
            render={({ message }) => <ErrorMessage message={message} />}
          />
        </FloatingFormFieldWrapper>

        <div className="grid grid-cols-1 gap-4 lg:grid-cols-[7fr_3fr]">
          {/* Organization Input */}
          <FloatingFormFieldWrapper>
            <TextInput
              id="organization_input"
              placeholder={organizationPlaceholder}
              type="text"
              hasError={fieldHasError(errors, 'organization')}
              {...register('organization')}
            />
            <HookFormErrorMessage
              name="organization"
              errors={errors}
              render={({ message }) => <ErrorMessage message={message} />}
            />
            <FloatingLabel
              className="required"
              htmlFor="organization_input"
              label={organizationLabel}
            />
          </FloatingFormFieldWrapper>

          {/* Organization Size Input */}
          <FloatingFormFieldWrapper>
            <SelectController
              name="organizationSize"
              id="organization_size_input"
              control={control}
              options={organizationSizeDropdown}
              placeholder={organizationPlaceholder}
              hasError={fieldHasError(errors, 'organizationSize')}
              isMulti={false}
              customComponents={{ DropdownIndicator }}
            />
            <FloatingLabel
              className="required"
              htmlFor="organization_size_input"
              label={organizationSizeLabel}
            />
            <HookFormErrorMessage
              name="organizationSize"
              errors={errors}
              render={({ message }) => <ErrorMessage message={message} />}
            />
          </FloatingFormFieldWrapper>
        </div>

        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          {/* JobTitle Input */}
          <FloatingFormFieldWrapper>
            <TextInput
              id="jobtitle_input"
              placeholder={jobTitlePlaceholder}
              type="text"
              {...register('jobTitle')}
            />
            <FloatingLabel htmlFor="jobtitle_input" label={jobTitleLabel} />
          </FloatingFormFieldWrapper>

          {/* Location Input */}
          <FloatingFormFieldWrapper>
            <TextInput
              id="location_input"
              placeholder={locationPlaceholder}
              type="text"
              {...register('location')}
            />
            <FloatingLabel htmlFor="location_input" label={locationLabel} />
          </FloatingFormFieldWrapper>
        </div>

        {/* Industry Input */}
        <FloatingFormFieldWrapper>
          <SelectController
            name="industry"
            id="industry_input"
            control={control}
            options={industryDropdown}
            placeholder={industryPlaceholder}
            hasError={fieldHasError(errors, 'industry')}
            isMulti={false}
            customComponents={{ DropdownIndicator }}
          />
          <FloatingLabel htmlFor="industry_input" label={industryLabel}></FloatingLabel>
        </FloatingFormFieldWrapper>

        {/* How did you hear about us Input */}
        <FloatingFormFieldWrapper>
          <SelectController
            name="howDidYouHearAboutUs"
            id="how_did_you_hear_about_us_input"
            control={control}
            options={howDidYouHearAboutUsDropdown}
            placeholder={howDidYouHearAboutUsPlaceholder}
            hasError={fieldHasError(errors, 'howDidYouHearAboutUs')}
            isMulti={false}
            customComponents={{ DropdownIndicator }}
          />
          <FloatingLabel
            htmlFor="how_did_you_hear_about_us_input"
            label={howDidYouHearAboutUsLabel}
          ></FloatingLabel>
        </FloatingFormFieldWrapper>

        {/* Message Input */}
        <FloatingFormFieldWrapper>
          <TextAreaInput
            id="message_input"
            placeholder={messagePlaceholder}
            rows={4}
            hasError={fieldHasError(errors, 'message')}
            {...register('message')}
          ></TextAreaInput>
          <FloatingLabel htmlFor="message_input" label={messageLabel}></FloatingLabel>
          <HookFormErrorMessage
            name="message"
            errors={errors}
            render={({ message }) => <ErrorMessage message={message} />}
          />
        </FloatingFormFieldWrapper>
        <div className="flex w-full flex-col items-center justify-between gap-4 sm:flex-row">
          <div className="flex w-full flex-col gap-4">
            <FormFieldWrapper>
              <div className="flex w-full items-start gap-2">
                <div className="relative flex h-[16px] w-[16px] cursor-pointer align-middle">
                  <CheckboxInput
                    type="checkbox"
                    id="consent_input"
                    className="h-full w-full border border-[#D4D4D8] text-sm text-zinc-500 checked:hover:bg-base-brand checked:focus:bg-base-brand"
                    {...register('consent')}
                  ></CheckboxInput>
                </div>
                <Label
                  htmlFor="consent_input"
                  label={Consent.communicationConsent}
                  className="required"
                ></Label>
              </div>
              <HookFormErrorMessage
                name="consent"
                errors={errors}
                render={({ message }) => <ErrorMessage message={message} />}
              />
            </FormFieldWrapper>

            <FormActionButton
              type="submit"
              className="max-w-max bg-base-brand disabled:opacity-80"
              disabled={isLoading}
            >
              <div className="flex flex-row items-center justify-center py-2 text-base font-semibold">
                {isLoading ? (
                  <div className="flex gap-2">
                    <span>{contactUsButton}</span>
                    <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
                  </div>
                ) : (
                  <div className="flex items-center justify-between gap-4">
                    <span>{contactUsButton}</span>
                    <div>
                      <ArrowRight
                        size="1rem"
                        color="white"
                        weight="bold"
                        className="ml-2"
                        aria-hidden={true}
                      />
                    </div>
                  </div>
                )}
              </div>
            </FormActionButton>
          </div>
          <Markdown className="w-full text-left lg:text-right">{Consent.paragraph}</Markdown>
        </div>
      </form>
    </FormStyleProvider>
  );
};

export default GetInTouchForm;
