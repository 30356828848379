import clsx from 'clsx';
import { Graph, IconProps, Megaphone, MonitorPlay, UsersThree } from 'phosphor-react';
import { useStrapiCLPData } from '../../../hooks/useStrapiCLPData';
import { ServiceItemStrapi } from '../../../types';
import CLPServicesGridItem, { Props as CLPServicesGridItemProps } from './CLPServicesGridItem';
import CLPServicesGridActionItem from './CLPServicesGridActionItem';
const iconMap: Record<
  string,
  React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>
> = {
  // eslint-disable-next-line camelcase
  Monitor: MonitorPlay,
  // eslint-disable-next-line camelcase
  Megaphone: Megaphone,
  // eslint-disable-next-line camelcase
  Graph: Graph,
  // eslint-disable-next-line camelcase
  People: UsersThree,
};
const classNameMap: Record<string, { buttonClassName: string; iconClassName: string }> = {
  Green: {
    buttonClassName: 'border-base-green bg-base-green',
    iconClassName: 'text-base-green',
  },
  // eslint-disable-next-line camelcase
  Bright_Green: {
    buttonClassName: 'border-base-bright-green bg-base-bright-green text-black',
    iconClassName: 'text-base-bright-green',
  },
  // eslint-disable-next-line camelcase
  Soft_Black: {
    buttonClassName: 'border-base-soft-black bg-base-soft-black',
    iconClassName: 'text-white',
  },
  Black: {
    buttonClassName: 'border-black bg-black text-white',
    iconClassName: 'text-white',
  },
  Brand: {
    buttonClassName: 'border-base-brand bg-base-brand ',
    iconClassName: 'text-base-brand',
  },
  Tangerine: {
    buttonClassName: 'border-base-tangerine bg-base-tangerine ',
    iconClassName: 'text-base-tangerine',
  },
};
const CLPServicesGrid = () => {
  const {
    ourServices: { Services },
    featureFlag: { showHQPlus, lastServiceCardTakesFullWidth },
  } = useStrapiCLPData();

  const serviceGridItems = [...Services].map((service: ServiceItemStrapi) => {
    const { buttonClassName, iconClassName } = classNameMap[service.theme];
    const IconComponent = service.Icon && iconMap[service.Icon];
    const Icon = IconComponent ? (
      <IconComponent
        size={48}
        color="currentColor"
        weight="fill"
        className={iconClassName}
        aria-hidden={true}
      />
    ) : null;
    const slug = service.slug;
    const ActionItem = (
      <CLPServicesGridActionItem
        isActive={service.isActive}
        isExternal={service.isExternal}
        to={service.link}
        label={service.button}
        srLabel={service.buttonSRLabel}
        className={iconClassName}
      />
    );
    const heading = service.title;
    const src = service.serviceImage.data.attributes.url;
    const alt = service.serviceImage.data.attributes.alternativeText;
    const supportText = service.description;
    let isActive = service.isActive;
    if (service.slug === 'hq_plus') {
      isActive = showHQPlus && service.isActive;
    }
    const isExternal = service.isExternal;
    const to = service.link;
    return {
      Icon,
      ActionItem,
      heading,
      illustration: {
        src,
        alt,
      },
      supportText,
      isActive,
      isExternal,
      to,
      slug,
      buttonClassName,
      iconClassName,
    };
  });
  const activeServiceGridItems = serviceGridItems.filter(
    (serviceGridItem: CLPServicesGridItemProps) => serviceGridItem.isActive
  );

  const hasOddServiceGridItems = activeServiceGridItems.length % 2 == 1;
  const loneCardIndex = lastServiceCardTakesFullWidth ? activeServiceGridItems.length - 1 : 0;

  return (
    <div
      className={clsx(
        'grid auto-rows-auto grid-cols-1 justify-center gap-[var(--grid-gap,2.5rem)] md:grid-cols-2',
        'justify-items-center'
      )}
    >
      {activeServiceGridItems.map((serviceGridItem: CLPServicesGridItemProps, idx: number) => {
        if (!serviceGridItem.isActive) {
          return null;
        }

        let isLoneCard = false;

        // If we have multiple odd items then one would be left out so span the last one two rows
        if (hasOddServiceGridItems && idx === loneCardIndex) {
          isLoneCard = true;
        }

        return (
          <CLPServicesGridItem
            key={serviceGridItem.heading}
            {...serviceGridItem}
            className={clsx(
              'w-full',
              isLoneCard && 'col-span-1 md:relative md:left-[calc(50%_+_var(--grid-gap,2.5rem))]'
            )}
            buttonClassName={serviceGridItem.buttonClassName}
            iconClassName={serviceGridItem.iconClassName}
          />
        );
      })}
    </div>
  );
};

export default CLPServicesGrid;
