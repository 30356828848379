import { twMerge } from 'tailwind-merge';
import { useStrapiCLPData } from '../../features/combined-landing-page/hooks/useStrapiCLPData';
import { useStrapiDataHelper } from '../../hooks/useStrapiData';
import { NavItemMetaStrapiData } from '../../types';
import NavItem from './NavItem';
import { useEffect, useState } from 'react';
import DropDownMenu from '../DropDown';

interface Props {
  className?: string;
  variant: 'light' | 'dark';
}

const DesktopNavMenu = ({ className, variant }: Props) => {
  const data = useStrapiDataHelper();
  const strapiNavData = data?.nav?.data?.attributes;
  const navItems: NavItemMetaStrapiData[] = strapiNavData?.PublicSiteNavBar || [];

  const {
    featureFlag: { showHQPlus, showAboutUs },
  } = useStrapiCLPData();

  const [primaryItems, setPrimaryItems] = useState<NavItemMetaStrapiData[]>([]);
  const [dropItems, setDropItems] = useState<Record<string, NavItemMetaStrapiData[]>>({});

  useEffect(() => {
    if (!navItems.length) return;

    const primary: NavItemMetaStrapiData[] = [];
    const drop: Record<string, NavItemMetaStrapiData[]> = {};

    navItems.forEach((item: NavItemMetaStrapiData) => {
      const { parentElement } = item;

      if (!parentElement) {
        primary.push(item);
      } else {
        drop[parentElement] = [...(drop[parentElement] || []), item];
      }
    });
    setDropItems(drop);
    setPrimaryItems(primary);
  }, [navItems]);

  return (
    <nav className={twMerge('hidden md:block', className)}>
      <ul className="flex flex-row gap-6 md:gap-8">
        {primaryItems.map((navItem: NavItemMetaStrapiData) => {
          const { isActive, isExternal, label, link, slug } = navItem;
          if (
            (slug === 'hq_plus' && !showHQPlus) ||
            (slug === 'about_us' && !showAboutUs) ||
            !isActive
          ) {
            return null;
          }

          return (
            <li key={slug}>
              <NavItem
                isNavLink={true}
                isExternal={isExternal}
                to={link}
                variant={variant}
                className="whitespace-nowrap"
              >
                {label}
              </NavItem>
            </li>
          );
        })}
        {Object.keys(dropItems).map((parentElement: string) => {
          return (
            <li key={parentElement}>
              <DropDownMenu
                label={parentElement}
                content={dropItems[parentElement]}
                variant={variant}
                className="whitespace-nowrap"
                contentSideOffset={1}
              />
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default DesktopNavMenu;
