import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

export interface Props {
  slug: string;
  className?: string;
  Icon: React.ReactNode;
  ActionItem: React.ReactNode;
  heading: string;
  illustration: {
    src: string;
    alt: string;
  };
  supportText: string;
  isActive: boolean;
  buttonClassName?: string;
  iconClassName?: string;
}

const CLPServicesGridItem = (props: Props) => {
  const { className, heading, illustration, ActionItem, buttonClassName, iconClassName } = props;

  return (
    <article className={twMerge(clsx('flex w-full flex-col gap-10', className))}>
      <div
        className={clsx(
          'relative w-full overflow-hidden rounded-t-[1.25rem] border-b-4',
          buttonClassName
        )}
      >
        <img
          src={illustration.src}
          alt={illustration.alt}
          className="aspect-[16/9] w-full object-cover"
        ></img>
        <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent" />
        <div className="absolute bottom-0 left-0 flex w-full items-center justify-between p-6">
          <h3 className="text-lg font-semibold text-white sm:text-xl md:text-2xl">{heading}</h3>
          {ActionItem && <div className={clsx(iconClassName)}>{ActionItem}</div>}
        </div>
      </div>
    </article>
  );
};

export default CLPServicesGridItem;
