import clsx from 'clsx';
import GetInTouchForm from './GetInTouchForm';
import GetInTouchFormHeading from './GetInTouchFormHeading';

interface Props {
  className?: string;
}

const GetInTouchFormMain = ({ className }: Props) => {
  return (
    <section className={clsx('flex w-full flex-col items-center justify-center gap-8', className)}>
      <GetInTouchFormHeading className="px-[var(--common-x-padding)]" />
      <GetInTouchForm />
    </section>
  );
};

export default GetInTouchFormMain;
