import { ArrowRight } from 'phosphor-react';
import { SwiperCarouselITem, SwiperCarouselTypes } from '../../types';

const CourseSlideItem = (props: SwiperCarouselITem) => {
  return props.carouselType == SwiperCarouselTypes.LOGOS ? (
    <div
      className="m-5 flex h-5 w-full flex-1 items-center justify-center px-1 md:p-10
    "
    >
      <img
        className="flex-1 object-cover"
        style={{ maxWidth: '70%' }}
        src={props.image}
        alt="Card"
      />
    </div>
  ) : (
    <div className="card-container relative h-96 w-96 overflow-hidden rounded-lg">
      {props.image && <img className="h-full w-full object-cover" src={props.image} />}
      <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent">
        <div className="absolute inset-x-0 bottom-0 p-4">
          <div className="mb-2 flex items-center">{props.divElement}</div>
          <p className="mb-4 text-sm text-gray-200">{props.label}</p>
          <div className="absolute bottom-8 right-4">
            <ArrowRight size={20} color="#fff" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseSlideItem;
