import clsx from 'clsx';
import CLPHeroContent from './CLPHeroContent';
import CLPHeroIllustration from './CLPHeroIllustration';
import { CaretDoubleDown } from 'phosphor-react';
import { Button } from '../../../../components/Buttons';
import { useState } from 'react';
interface Props {
  className?: string;
}

const CLPHero = ({ className }: Props) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const handleVideoPlay = (isPlaying: boolean) => {
    setIsVideoPlaying(isPlaying);
  };

  const handleScroll = () => {
    const section = document.getElementById('hq-services');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <section
      className={clsx(
        'relative flex w-full flex-1 flex-col items-baseline justify-between gap-8 sm:items-center md:flex-row md:flex-wrap xl:gap-14',
        isVideoPlaying ? 'pr-[var(--common-x-padding)]' : 'pl-[var(--common-x-padding)]',
        className
      )}
    >
      <CLPHeroContent className="justify-start" isVideoPlaying={isVideoPlaying} />
      <CLPHeroIllustration onVideoPlay={handleVideoPlay} />
      {!isVideoPlaying && (
        <div
          className="absolute right-0 top-[calc(100%-6rem)] -z-50 hidden h-80  w-60 cursor-pointer flex-col items-center justify-center bg-rectangle-light xl:flex "
          onClick={handleScroll}
        >
          <Button className="cursor-pointer">
            <CaretDoubleDown
              size={66}
              width={38}
              height={38}
              weight="thin"
              color="#F7634F"
              className="h-28 w-24 cursor-pointer text-inherit transition-transform group-hover:translate-x-1 motion-reduce:group-hover:translate-x-0 "
            ></CaretDoubleDown>
          </Button>
        </div>
      )}
    </section>
  );
};

export default CLPHero;
